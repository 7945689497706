var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.choices)?_c('div',{staticClass:"manage-order"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-1"},[_c('div',{staticStyle:{"height":"36px","margin-top":"7px"}},[_vm._v("1. Sarake")]),_c('div',{staticStyle:{"height":"36px"}},[_vm._v("2. Sarake")]),_c('div',{staticStyle:{"height":"36px"}},[_vm._v("loput Sarakkeet")])]),_c('v-col',{staticClass:"col-2"},_vm._l((_vm.pivot),function(element){return _c('div',{key:element},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.movePivotUp(element)}}},[_c('v-icon',[_vm._v("mdi-arrow-up-bold")])],1),_vm._v(_vm._s(_vm.pivotName[element])+" ")],1)}),0),_c('v-col',{staticClass:"col-3"},[_c('v-checkbox',{attrs:{"label":'Muokkaustila'},model:{value:(_vm.editMode),callback:function ($$v) {_vm.editMode=$$v},expression:"editMode"}}),_c('v-checkbox',{attrs:{"label":'Varasto+Viikko'},model:{value:(_vm.storeDateMode),callback:function ($$v) {_vm.storeDateMode=$$v},expression:"storeDateMode"}})],1)],1),(!_vm.order && _vm.fetchingStatus)?_c('v-row',[_c('v-col',{staticClass:"d-flex col-12 justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_vm._e()],1),_c('div',{ref:"table-wrapper",staticClass:"table-wrapper"},[_c('ShiftPainter',{attrs:{"relative-position":true}}),(_vm.order && _vm.order.groups)?_c('table',{style:(_vm.tableStyle)},[_c('thead',[_c('tr',[_vm._l((_vm.columns[0]),function(column){return _c('th',{key:column},[_vm._v(_vm._s(column))])}),_vm._l((_vm.thirdGroupChoices),function(thirdChoice){return _c('th',{key:`th-third-${thirdChoice.value}`,class:{
            'small-col': _vm.pivot[2] == 'store_date',
          }},[_c('pre',[_vm._v(_vm._s(thirdChoice.text))])])}),_c('th',[_vm._v("Yhteensä")]),_c('th',[_vm._v("Yhteensä (€)")])],2)]),_vm._l((_vm.sortedGroups),function(firstGroup,firstGroupIndex){return _c('disappearing-t-body',{key:`firstGroup-${firstGroupIndex}`,class:`firstGroup-${firstGroup[_vm.selectedFirstGroup.idKey]}`,attrs:{"initial-height":`${19 * _vm.secondaryGroups(firstGroup).length}px`},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(_vm._s(_vm.columns[1][0][0](firstGroup)))]},proxy:true}],null,true)},_vm._l((_vm.secondaryGroups(firstGroup)),function(secondGroup,index){return _c('tr',{key:`secondGroup-${index}`,class:{
          'linkedgroup-highlight':
            firstGroup[_vm.selectedFirstGroup.idKey] === _vm.linkedGroupLocal,
        }},[_vm._l((_vm.columns[1][0]),function(valueCallback,colIndex){return _c('th',{key:`${firstGroupIndex}-${index}-${colIndex}`,staticClass:"text-cell"},[(index == 0)?_c('span',[_vm._v(_vm._s(valueCallback(firstGroup)))]):_vm._e()])}),_vm._l((_vm.columns[1][1]),function(valueCallback,colIndex){return _c('th',{key:`${firstGroupIndex}-${index}-${colIndex + _vm.columns[1][0].length
            }`},[_vm._v(" "+_vm._s(valueCallback(secondGroup))+" ")])}),_vm._l((_vm.thirdGroupChoices),function(thirdChoice){return _c('selectable-cell',{key:`td-thirdGroup-${thirdChoice.value}`},[_c('pivot-order-input-modal',{attrs:{"third-group":secondGroup.groups[thirdChoice.value],"sizes":_vm.sizesById,"stores":_vm.allStores,"editable":_vm.editMode}})],1)}),_c('td',[_c('cell-input',{attrs:{"disabled":true,"value":`${_vm.secondGroupGlobalSums(secondGroup).quantity}`}})],1),_c('td',[_c('cell-input',{attrs:{"disabled":true,"value":`${_vm.secondGroupGlobalSums(secondGroup).net_price}`}})],1)],2)}),0)}),_c('tbody',[_c('tr',[_vm._l((_vm.columns[0]),function(column){return _c('th',{key:column})}),_vm._l((_vm.thirdGroupChoices),function(thirdChoice){return _c('selectable-cell',{key:`totals-td-thirdGroup-${thirdChoice.value}`},[_c('cell-input',{attrs:{"disabled":true,"value":`${_vm.verticalSums(thirdChoice).quantity}`}})],1)}),_c('selectable-cell',{staticStyle:{"min-width":"100px"}},[_c('cell-input',{attrs:{"disabled":true,"value":`${_vm.totalGlobalSums.quantity}`}})],1),_c('selectable-cell',{staticStyle:{"min-width":"100px"}},[_c('cell-input',{attrs:{"disabled":true,"value":`${_vm.totalGlobalSums.net_price}`}})],1)],2)])],2):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }