<template>
  <td
    tabindex="0"
    ref="td"
    class="keyboard-navigation"
    :class="{
      hover,
      'painted-empty': painted === 'empty',
      'painted-copy': painted === 'copy',
      'painted-copy-source': painted === 'copy-source',
    }"
    @keydown="keydown"
    @mousedown="mousedown"
    @mouseup="mouseup"
    @mouseover="mouseover"
    @mouseleave="hover = false"
  >
    <disappearing-component v-if="disappearEnabled">
      <slot></slot>
    </disappearing-component>
    <slot v-else></slot>
  </td>
</template>

<script>
import DisappearingComponent from "./DisappearingComponent.vue";
// TD element with Keyboard navigation

export default {
  components: { DisappearingComponent },
  name: "SelectableCell",

  props: {
    value: {
      type: null,
    },
    row: { type: null },
    disappearEnabled: { type: Boolean, default: false },
  },

  data: () => ({
    hover: false,
  }),

  computed: {
    painted() {
      if (!this.row) {
        return null;
      }
      const ret =
        this.$store.state.ordergrid.paintedOrderRows[
          this.row.store_date_size_color_group_str
        ];
      if (!ret && !this.row.quantity) {
        return "empty";
      }
      return ret;
    },
  },

  methods: {
    mouseover(event) {
      this.hover = true;
      console.log("mouseover");
      if (this.$store.state.modifierKeys.Mouse) {
        this.$store.commit("ordergrid/setShiftSelectEndCell", this.$refs.td);
      }
    },
    mouseup(event) {
      this.mousedown(event, { mouseup: true });
    },
    mousedown(event, overrides) {
      overrides = overrides || {};
      const el = this.$refs.td;
      const inputEl = el.querySelector("input");
      const resetShiftSelect =
        !overrides.mouseup && !this.$store.state.modifierKeys.Shift;
      this.updateShiftSelect(resetShiftSelect);
      if (document.activeElement === inputEl) {
        return; // All good already
      } else if (document.activeElement === el && inputEl) {
        inputEl.focus();
      } else {
        el.focus();
      }
      this.$store.commit("ordergrid/setShiftSelectEndCell", this.$refs.td);
      event.preventDefault();
    },
    enterPressed() {
      const el = this.$refs.td;
      const inputEl = el.querySelector("input");
      inputEl.focus();
      if (inputEl.getAttribute("disabled")) {
        const wrapper = el.querySelector(".wrapper");
        if (wrapper.__vue__ && wrapper.__vue__.showModal) {
          wrapper.__vue__.showModal();
        }
      }
      console.log("Enter: ", inputEl);
    },
    startTyping(event) {
      const el = this.$refs.td;
      const inputEl = el.querySelector("input");
      if (this.$store.state.modifierKeys.Control) {
        if (event.code === "KeyC") {
          if (this.$store.state.ordergrid.shiftSelectEndCell) {
            this.$store.commit("ordergrid/setShiftSelectAction", "copy");
          }
        } else if (event.code === "KeyX") {
          if (this.$store.state.ordergrid.shiftSelectEndCell) {
            this.$store.commit("ordergrid/setShiftSelectAction", "cut");
          }
        } else if (event.code === "KeyV") {
          if (this.$store.state.ordergrid.shiftSelectEndCell) {
            this.$store.commit("ordergrid/setShiftSelectAction", "paste");
          }
        }
      } else if (event.code === "Delete" || event.code === "Backspace") {
        inputEl.value = "";
        inputEl.parentElement.__vue__.clear();
        if (this.$store.state.ordergrid.shiftSelectStartCell) {
          this.$store.commit("ordergrid/setShiftSelectAction", "delete");
        }
      } else {
        inputEl.focus();
        inputEl.select();
      }
    },
    updateShiftSelect(forceShift) {
      // If shift is currently held, and stored shift select is null, set this td.
      if (
        forceShift ||
        this.$store.state.modifierKeys.Shift ||
        this.$store.state.modifierKeys.Mouse
      ) {
        if (forceShift) {
          this.$store.commit(
            "ordergrid/setShiftSelectStartCell",
            this.$refs.td
          );
        } else if (!this.$store.state.ordergrid.shiftSelectStartCell) {
          // Try to find focused td first
          let focusedTd = document.querySelector("td:focus");
          if (!focusedTd) {
            focusedTd = (document.querySelector("td > input:focus") || {})
              .parentElement;
          }
          if (focusedTd) {
            this.$store.commit("ordergrid/setShiftSelectStartCell", focusedTd);
          } else {
            this.$store.commit(
              "ordergrid/setShiftSelectStartCell",
              this.$refs.td
            );
          }
        }
        return true;
      } else {
        // If shift is not held, and stored shift select is not null, clear it.
        if (this.$store.state.ordergrid.shiftSelectStartCell) {
          this.$store.commit("ordergrid/setShiftSelectStartCell", null);
        }
      }
    },
    navigate(x, y) {
      let nthTD = 1 + x;
      const el = this.$refs.td;
      let child = el;
      let tr = el.parentElement;
      const sizeCount = el.parentElement.querySelectorAll("td.size-td").length;
      const startColCount =
        el.parentElement.querySelectorAll("td.start-td").length;

      while ((child = child.previousElementSibling) != null) nthTD++;

      // Loop through all rows in the whole document
      const trList = document.querySelectorAll("tr:not(.dategroup-hidden");
      let counter = 0;
      let nextTR = null;
      for (const tmpTR of trList) {
        if (tr === tmpTR) {
          // Found the current row! Result is in index `counter + y`
          nextTR = trList[counter + y];
          break;
        }
        counter += 1;
      }

      if (!nextTR) {
        console.log("Reached the end?");
        return;
      }

      const nextSizeCount = nextTR.querySelectorAll("td.size-td").length;
      const nextStartColCount = nextTR.querySelectorAll("td.start-td").length;
      if (el.classList.contains("size-td")) {
        // Round the index to match the next TD
        const sizeIndex = nthTD - startColCount;
        const nextSizeIndex = sizeIndex * (nextSizeCount / sizeCount);
        nthTD = Math.round(nextStartColCount + nextSizeIndex);
      } else {
        nthTD += nextSizeCount - sizeCount;
      }

      let nextTD = nextTR.querySelector(`td:nth-child(${nthTD})`);
      if (!nextTD || !nextTD.classList.contains("keyboard-navigation")) {
        if (y != 0) {
          // Jump over a tr
          if (y < -3 || y > 3) {
            nextTD = this.$refs.td;
          } else {
            return this.navigate(x, y + (y > 0 ? 1 : -1));
          }
        }
        if (x != 0) {
          // Jump over a td
          if (x < -3 || x > 3) {
            nextTD = this.$refs.td;
          } else {
            return this.navigate(x + (x > 0 ? 1 : -1), y);
          }
        } else {
          nextTD = this.$refs.td;
        }
      }
      const shiftPainting = this.updateShiftSelect();
      if (shiftPainting && (y > 1 || x > 1)) {
        console.log("Can't jump over while shift painting!");
        return;
      }
      nextTD.focus();
      this.$store.commit("ordergrid/setShiftSelectEndCell", nextTD);
    },
    keydown(event) {
      if (event.target.tagName !== "TD") {
        // Typing into the input element
        if (event.code == "Enter") {
          this.navigate(0, 1);
          event.preventDefault();
          return;
        }
        console.log("Typing into input element: ", event.code);
        if (event.key == "Shift") {
          // Shift focus from input el
          this.$refs.td.focus();
          this.$nextTick(() => {
            this.updateShiftSelect();
          });
        } else if (this.$store.state.modifierKeys.Control) {
          // Allow paste
          console.log("Inputting modifier to cell", event.code);
          // this.$store.commit("ordergrid/setShiftSelectStartCell", null);
          // this.$store.commit("ordergrid/setShiftSelectEndCell", this.$refs.td);
          event.preventDefault();
        } else if (!event.code.startsWith("Arrow")) {
          // Allow tyring normally
          return;
        }
      }
      let x = 0;
      let y = 0;
      if (event.code == "ArrowRight") {
        x += 1;
      } else if (event.code == "ArrowLeft") {
        x -= 1;
      } else if (event.code == "ArrowUp") {
        y -= 1;
      } else if (event.code == "ArrowDown") {
        y += 1;
      } else if (event.code == "Enter") {
        this.enterPressed();
        event.preventDefault();
        return;
      } else if (
        event.code.startsWith("Key") ||
        !isNaN(Number(event.key)) ||
        event.code == "Backspace" ||
        event.code == "Delete"
      ) {
        this.startTyping(event);
        return;
      } else {
        console.log(event);
        return;
      }
      event.preventDefault();
      if (x !== 0 || y !== 0) {
        this.navigate(x, y);
      }
    },
  },
};
</script>

<style scoped lang="scss">
td:focus {
  outline-width: 4px;
  outline-style: inset;
  outline-color: blue;
  outline-offset: -2px;
}
td.hover {
  outline-width: 2px;
  outline-style: double;
  outline-color: #0d0f14;
  outline-offset: -2px;
}
.painted-empty {
  background-color: rgba(233, 150, 122, 0.534);
}
.painted-copy {
  background-color: rgb(164, 255, 136);
}
.painted-copy-source {
  background-color: rgb(243, 255, 136);
}
</style>
