
import { modifyObj } from "../utils/utils";
import { cloneDeep } from "lodash";
import axios from "axios";

const applyChanges = ({ state, commit }) => {
  console.log("applyChanges: ", state.saveValues);
  const rowsToSend = [];
  const oldRowsToSend = [];
  const clonedValues = [];
  const callbacks = [];
  for (const values of Object.values(state.saveValues)) {
    const newRow = cloneDeep(values.newRow);
    rowsToSend.push(newRow);
    const oldRow = cloneDeep(values.oldRow);
    oldRowsToSend.push(oldRow);
    const newRowRef = values.newRow;
    const oldRowRef = values.oldRow;
    clonedValues.push({ newRow, oldRow, newRowRef, oldRowRef });
    if (values.changed) {
      callbacks.push(values.changed);
    }
  }
  commit(
    "addJob",
    {
      callback: () => {
        console.debug("saveQuantity: ", rowsToSend);
        // Modify here for UX, then after response for the id
        for (const { newRow, oldRow, newRowRef, oldRowRef } of clonedValues) {
          modifyObj(newRowRef, newRow);
          modifyObj(oldRowRef, newRow);
        }
        return axios
          .post(`/api/orders/order/${state.pickedOrderId}/update_rows/`, {
            rows: rowsToSend,
          })
          .then((resp) => {
            console.log("saveQuantity resp:", resp);
            // Replace the old row data with new (keep the reference!)
            if (resp.data.updated.length > 0) {
              for (const updatedRow of resp.data.updated) {
                for (const clonedValue of clonedValues) {
                  if (
                    updatedRow.store_date_size_color_group_str ==
                    clonedValue.newRow.store_date_size_color_group_str
                  ) {
                    console.log("Found updated row:", updatedRow, clonedValue);
                    modifyObj(clonedValue.newRowRef, updatedRow);
                    modifyObj(clonedValue.oldRowRef, updatedRow);
                    break;
                  }
                }
              }
            }
            for (const callback of callbacks) {
              callback();
            }
          });
      },
      undo: () => {
        console.debug("Undoing saveQuantity: ", clonedValues);
        for (const { newRow, oldRow, newRowRef, oldRowRef } of clonedValues) {
          modifyObj(newRowRef, oldRow);
          modifyObj(oldRowRef, oldRow);
        }
        return axios
          .post(`/api/orders/order/${state.pickedOrderId}/update_rows/`, {
            rows: oldRowsToSend,
          })
          .then((resp) => {
            for (const callback of callbacks) {
              callback();
            }
            console.log(resp);
          });
      },
    },
    {
      root: true,
    }
  );
};

export const rowsaver = {
  namespaced: true,
  state: () => ({
    saveValues: {},
    debounceTimeout: null,
    pickedOrderId: null,
  }),
  mutations: {},
  actions: {
    setRowValues(context, { newRow, oldRow, pickedOrderId, changed }) {
      const { state } = context;
      if (
        newRow.quantity == oldRow.quantity ||
        (!newRow.quantity && !oldRow.quantity)
      ) {
        return;
      }
      state.pickedOrderId = pickedOrderId;
      state.saveValues[newRow.store_date_size_color_group_str] = {
        newRow,
        oldRow,
        changed,
      };
      if (state.debounceTimeout) {
        clearTimeout(state.debounceTimeout);
      }
      state.debounceTimeout = setTimeout(applyChanges, 1000, context);
    },
  },
  getters: {},
};
