<template>
  <div class="manage-order" v-if="choices">
    <v-container>
      <v-row>
        <v-col class="col-1">
          <div style="height: 36px; margin-top: 7px">1. Sarake</div>
          <div style="height: 36px">2. Sarake</div>
          <div style="height: 36px">loput Sarakkeet</div>
        </v-col>
        <v-col class="col-2">
          <div v-for="element in pivot" :key="element">
            <v-btn icon @click="movePivotUp(element)"><v-icon>mdi-arrow-up-bold</v-icon></v-btn>{{ pivotName[element] }}
          </div>
        </v-col>
        <v-col class="col-3">
          <v-checkbox v-model="editMode" :label="'Muokkaustila'"></v-checkbox>
          <v-checkbox v-model="storeDateMode" :label="'Varasto+Viikko'"></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="!order && fetchingStatus">
        <v-col class="d-flex col-12 justify-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <div class="table-wrapper" ref="table-wrapper">
      <ShiftPainter :relative-position="true"></ShiftPainter>
      <table v-if="order && order.groups" :style="tableStyle">
        <thead>
          <tr>
            <!-- Start "Left side" -->
            <th v-for="column in columns[0]" :key="column">{{ column }}</th>

            <!-- End "Left side" -->
            <!-- Start "Middle" -->
            <th v-for="thirdChoice in thirdGroupChoices" :key="`th-third-${thirdChoice.value}`" :class="{
              'small-col': pivot[2] == 'store_date',
            }">
              <pre>{{ thirdChoice.text }}</pre>
            </th>
            <!-- End "Middle" -->
            <!-- Start "Right side" -->
            <th>Yhteensä</th>
            <th>Yhteensä (€)</th>
            <!-- End "Right side" -->
          </tr>
        </thead>
        <disappearing-t-body v-for="(firstGroup, firstGroupIndex) in sortedGroups" :key="`firstGroup-${firstGroupIndex}`"
          :class="`firstGroup-${firstGroup[selectedFirstGroup.idKey]}`"
          :initial-height="`${19 * secondaryGroups(firstGroup).length}px`"
          >
          <template v-slot:empty>{{ columns[1][0][0](firstGroup) }}</template>
          <tr v-for="(secondGroup, index) in secondaryGroups(firstGroup)" :key="`secondGroup-${index}`" :class="{
            'linkedgroup-highlight':
              firstGroup[selectedFirstGroup.idKey] === linkedGroupLocal,
          }">
            <th v-for="(valueCallback, colIndex) in columns[1][0]" :key="`${firstGroupIndex}-${index}-${colIndex}`"
              class="text-cell">
              <span v-if="index == 0">{{ valueCallback(firstGroup) }}</span>
            </th>
            <th v-for="(valueCallback, colIndex) in columns[1][1]" :key="`${firstGroupIndex}-${index}-${colIndex + columns[1][0].length
              }`">
              {{ valueCallback(secondGroup) }}
            </th>
            <selectable-cell v-for="thirdChoice in thirdGroupChoices" :key="`td-thirdGroup-${thirdChoice.value}`">
              <pivot-order-input-modal :third-group="secondGroup.groups[thirdChoice.value]" :sizes="sizesById"
                :stores="allStores" :editable="editMode" />
            </selectable-cell>
            <td>
              <cell-input :disabled="true" :value="`${secondGroupGlobalSums(secondGroup).quantity}`" />
            </td>
            <td>
              <cell-input :disabled="true" :value="`${secondGroupGlobalSums(secondGroup).net_price}`" />
            </td>
          </tr>
        </disappearing-t-body>
        <tbody>
          <tr>
            <th v-for="column in columns[0]" :key="column"></th>

            <selectable-cell v-for="thirdChoice in thirdGroupChoices" :key="`totals-td-thirdGroup-${thirdChoice.value}`">
              <cell-input :disabled="true" :value="`${verticalSums(thirdChoice).quantity}`" />
            </selectable-cell>
            <selectable-cell style="min-width: 100px">
              <cell-input :disabled="true" :value="`${totalGlobalSums.quantity}`" />
            </selectable-cell>
            <selectable-cell style="min-width: 100px">
              <cell-input :disabled="true" :value="`${totalGlobalSums.net_price}`" />
            </selectable-cell>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// This component uses a dynamically pivoted order.

// Third group will be exploded into separate columns

import { debounce } from "lodash";

import CellInput from "../components/CellInput.vue";
import PivotOrderInputModal from "../components/PivotOrderInputModal.vue";
import SelectableCell from "../components/SelectableCell.vue";
import ShiftPainter from "../components/ShiftPainter.vue";
import DisappearingTBody from "../components/DisappearingTBody.vue";

import ChoicesOrderVue from "../mixins/ChoicesOrder.vue";
import QuerySave from "../mixins/QuerySave.vue";
import OrderHelpersVue from "../mixins/OrderHelpers.vue";

export default {
  name: "PivotOrder",
  components: {
    CellInput,
    PivotOrderInputModal,
    SelectableCell,
    ShiftPainter,
    DisappearingTBody,
  },
  mixins: [QuerySave, ChoicesOrderVue, OrderHelpersVue],
  data: () => ({
    order: null,
    fetchingStatus: null,
    pivot: ["color_group", "size", "store_date"],
    storeDateMode: true,
    querySave: ["editMode", "pivot", "storeDateMode"],
    editMode: true,
    choices: {},

    linkedGroupLocal: null,
    tableStyle: {
      display: "none",
    },
  }),
  mounted() {
    this.$store.commit('disappearingHelper/clearComponentVisible');
    this.fetchOrder();
  },
  computed: {
    columns() {
      // return [header list, row list], with row list having callables for values
      // [
      //  ["Header1-1", "Header1-2", "Header2-1"],
      //  [
      //   [row1-1Callback, row1-1Callback],
      //   [row2-1Callback]
      //  ]
      // ]
      const ret = [[], [[], []]];

      let counter = 0;
      for (const key of this.pivot.slice(0, 2)) {
        const choice = this._.find(this.groupingChoices, { value: key });
        ret[0].push(choice.text);
        ret[1][counter].push(choice.verboseName);
        counter++;
      }

      // Store extra columns for color groups temporarily
      let colorGroupValues = [[], []];
      colorGroupValues[0].push("Väri");
      if (this.order.types) {
        colorGroupValues[0].push("Tyyppi");
      }
      colorGroupValues[0].push("ENETTO");
      colorGroupValues[1].push((colorGroup) => `${colorGroup.color__name}`);
      if (this.order.types) {
        colorGroupValues[1].push(
          (colorGroup) => `${colorGroup.type__name || ""}`
        );
      }
      colorGroupValues[1].push(
        (colorGroup) => `${this.colorGroupKeyGetter(colorGroup, "net_price")}`
      );

      if (this.pivot[0] === "color_group") {
        ret[0].splice(1, 0, ...colorGroupValues[0]);
        ret[1][0] = ret[1][0].concat(colorGroupValues[1]);
      }

      if (this.pivot[1] === "color_group") {
        ret[0].splice(2, 0, ...colorGroupValues[0]);
        ret[1][1] = ret[1][1].concat(colorGroupValues[1]);
      }

      return ret;
    },
    pivotName() {
      const ret = {
        date: "Toimitusvko",
        store: "Varasto",
        color_group: "Tuotemalli",
        size: "Koko",
        store_date: "Varasto+Viikko",
      };
      return ret;
    },
    groupingChoices() {
      return [
        {
          value: "color_group",
          idKey: "color_group_str",
          verboseName: (colorGroup) => `${colorGroup.name}`,
          verboseName2: (colorGroup) => `${colorGroup.color__name}`,
          verboseName3: (colorGroup) => `${colorGroup.type__name || ""}`,
          text: "Tuote",
        },
        {
          value: "date",
          idKey: "date",
          verboseName: (dateGroup) => {
            return `Viikko ${dateGroup.date}`;
          },
          text: "Toimitusvko",
        },
        {
          value: "store",
          idKey: "slug",
          verboseName: (storeGroup) => `${storeGroup.name}`,
          text: "Varasto",
        },
        {
          value: "store_date",
          idKey: "slug",
          verboseName: (store_date) => `${store_date.name}`,
          text: "Varasto+Viikko",
        },
        {
          value: "size",
          idKey: "slug",
          verboseName: (size) => `${size.name}`,
          text: "Koko",
        },
      ];
    },
    selectedFirstGroup() {
      return this._.find(this.groupingChoices, { value: this.pivot[0] });
    },
    selectedSecondGroup() {
      return this._.find(this.groupingChoices, { value: this.pivot[1] });
    },
    selectedThirdGroup() {
      return this._.find(this.groupingChoices, { value: this.pivot[2] });
    },
    thirdGroupChoices() {
      if (!this.choices.stores) {
        return [];
      }
      const thirdGroupSlug = this.selectedThirdGroup.value;
      if (thirdGroupSlug == "store_date") {
        const ret = [];
        for (const storeChoice of this.choices.stores) {
          for (const dateChoice of this.order.delivery_dates_by_store[
            storeChoice.id
          ]) {
            ret.push({
              value: `${storeChoice.id}_${dateChoice}`,
              text: `${storeChoice.abbr}\nV${dateChoice}`,
            });
          }
        }
        return ret;
      }
      if (thirdGroupSlug == "store") {
        // Just return all possible stores
        return this.choices.stores.map((store) => ({
          value: store.id,
          text: store.name,
        }));
      } else if (thirdGroupSlug == "date") {
        // Return all delivery dates for this order
        return this.order.delivery_dates.map((date) => ({
          value: date,
          text: `Viikko ${date}`,
        }));
      } else if (thirdGroupSlug == "color_group") {
        // Return all color groups for this order
        const ret = {};
        Object.values(this.order.selectedproducts).map((selectedproduct) => {
          ret[selectedproduct.product.color_group_str] = {
            value: selectedproduct.product.color_group_str,
            text: this.selectedThirdGroup.verboseName(selectedproduct.product),
          };
        });
        return Object.values(ret);
      }
      return [];
    },
    allStores() {
      const ret = {};
      if (!this.choices.stores) {
        return ret;
      }
      for (const store of this.choices.stores) {
        ret[store.id] = store;
      }
      return ret;
    },
    totalGlobalSums() {
      let ret = {
        quantity: 0,
        net_price: 0,
      };
      for (const firstGroup of Object.values(this.order.groups)) {
        this.firstGroupSums(firstGroup, ret);
      }
      return ret;
    },
    sizesById() {
      const ret = {};
      for (const sizeGroup of Object.values(this.order.all_sizes)) {
        for (const size of sizeGroup) {
          ret[size.id] = size;
        }
      }
      return ret;
    },
    debouncedFetchOrder() {
      return debounce(this.fetchOrder, 1000);
    },
    sortedGroups() {
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      const groups = Object.values(this.order.groups);
      if (this.selectedFirstGroup.value == "color_group") {
        return this._.sortBy(groups, (colorGroup) =>
          this.colorGroupKeyGetter(colorGroup, "selectedproduct__color_group")
        );
      }
      return this.order.groups;
    },
  },
  methods: {
    secondaryGroups(firstGroup) {
      if (this.selectedSecondGroup.value == "size") {
        return this._.sortBy(
          Object.values(firstGroup.groups),
          (group) => (this.sizesById[group.size] || {}).sort_order
        );
      }
      if (this.selectedSecondGroup.value == "store") {
        return this._.sortBy(
          Object.values(firstGroup.groups),
          (group) => group.sort_order
        );
      }
      // Sort by name
      return this._.sortBy(
        Object.values(firstGroup.groups),
        (group) => group[this.selectedSecondGroup.value]
      );
    },
    combinedGroups(firstGroup) {
      const ret = {};

      for (const key in firstGroup.groups) {
        for (const secondKey in firstGroup.groups[key].groups) {
          let combined;
          if (this.selectedSecondGroup.value == "store") {
            combined = `${key}_${secondKey}`;
          } else {
            combined = `${secondKey}_${key}`;
          }
          ret[combined] = firstGroup.groups[key].groups[secondKey];
        }
      }
      return ret;
    },
    movePivotUp(element) {
      const pivot = [...this.pivot];
      const index = pivot.indexOf(element);
      pivot.splice(index, 1);
      const newIndex = index != 0 ? index - 1 : 0;
      pivot.splice(newIndex, 0, element);
      this.pivot.splice(0, 3, ...pivot);
    },
    firstGroupSums(firstGroup, retArg) {
      let ret;
      if (retArg == undefined) {
        ret = {
          quantity: 0,
          net_price: 0,
        };
      } else {
        ret = retArg;
      }
      for (const secondGroup of Object.values(firstGroup.groups)) {
        this.secondGroupGlobalSums(secondGroup, ret);
      }
      return ret;
    },
    secondGroupGlobalSums(secondGroup, retArg) {
      let ret;
      if (retArg == undefined) {
        ret = {
          quantity: 0,
          net_price: 0,
        };
      } else {
        ret = retArg;
      }
      for (const thirdGroup of Object.values(secondGroup.groups)) {
        this.thirdGroupGlobalSums(thirdGroup, ret);
      }
      return ret;
    },
    thirdGroupGlobalSums(thirdGroup, ret) {
      for (const row of Object.values(thirdGroup.groups)) {
        const quantity = parseInt(row.quantity || 0);
        ret.quantity += quantity;
        const selectedproduct =
          this.order.selectedproducts[row.selectedproduct];
        const net_price =
          selectedproduct.net_price || selectedproduct.product.net_price;
        ret.net_price += quantity * net_price;
      }
    },
    thirdGroupSum(thirdGroup) {
      if (!thirdGroup) {
        return 0;
      }
      return Object.values(thirdGroup.groups).reduce(
        (acc, row) => parseInt(row.quantity || 0) + acc,
        0
      );
    },
    verticalSums(thirdGroupChoice) {
      let ret = {
        quantity: 0,
        net_price: 0,
      };
      for (const firstGroupKey in this.order.groups) {
        const secondaryGroups = this.secondaryGroups(
          this.order.groups[firstGroupKey]
        );
        for (const secondaryGroup of secondaryGroups) {
          const thirdGroup = secondaryGroup.groups[thirdGroupChoice.value];
          if (!thirdGroup) {
            continue;
          }
          for (const sizeGroupID in thirdGroup.groups) {
            const row = thirdGroup.groups[sizeGroupID];
            const quantity = parseInt(row.quantity || 0);
            ret.quantity += quantity || 0;
            ret.net_price +=
              quantity *
              this.order.selectedproducts[row.selectedproduct].product
                .net_price;
          }
        }
      }
      return ret;
    },
    fetchOrder() {
      if (this.loading) {
        return;
      }
      this.tableStyle.display = "none";
      this.loading = true;
      this.order = null;
      const pivot = this.pivot.join(",");
      this.fetchingStatus = pivot;
      this.axios
        .get(
          "/api/orders/order/" +
          this.$route.params.orderId +
          `/?grouping=${pivot}&all=${this.editMode}`
        )
        .then((resp) => {
          if (this.fetchingStatus == this.pivot.join(",")) {
            this.fetchingStatus = null;
            this.order = resp.data;
          } else {
            console.log("Wrong pivot from request!");
          }
        })
        .finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.tableStyle.display = "table";
          }, 1000);
        });
    },
  },

  watch: {
    pivot() {
      this.order = null;
      this.debouncedFetchOrder();
    },
    storeDateMode(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.pivot = ["color_group", "size", "store_date"];
      }
      if (!newValue && oldValue) {
        this.pivot = ["color_group", "store", "date"];
      }
    },
    editMode() {
      this.order = null;
      this.fetchOrder();
    },
  },
};
</script>

<style scoped lang="scss">
table {
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;
}

table {
  font-size: 12px;
}

td {
  padding: 0 0.2em;
  border: 1px solid black;
  word-break: break-all;
  min-width: 2.5em;

  & input {
    width: 50px;
  }

  &.input-number-cell {
    padding: 0 !important;

    >* {
      height: 100%;
    }
  }
}

th.small-col {
  width: 40px !important;
}

thead th {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  top: 0;
  background-color: white;
}

thead th:first-child {
  left: 0;
  z-index: 1;
}

tbody th:first-child {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  left: 0;
  background-color: white;
}

tbody th {
  border-bottom: 1px solid black;
}

td.text-cell,
th.text-cell {
  white-space: nowrap;
  text-align: left;
  padding-right: 0.5em;
}

tbody:nth-child(2n),
tbody:nth-child(2n) th {
  background-color: #e8ebec !important;
}

.table-wrapper {
  width: 99vw;
  height: 90vh;
  overflow: scroll;
  position: relative;
}

.linkedgroup-highlight {
  background-color: #dafada;
}
</style>
