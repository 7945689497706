var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.thisIsVisible)?_c('tbody',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    intersection: {
      rootMargin: '1000px',
      threshold: 0,
    },
  }),expression:"{\n    callback: visibilityChanged,\n    intersection: {\n      rootMargin: '1000px',\n      threshold: 0,\n    },\n  }"}],ref:"main"},[_vm._t("default")],2):_c('tbody',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    intersection: {
      rootMargin: '1000px',
      threshold: 0,
    },
  }),expression:"{\n    callback: visibilityChanged,\n    intersection: {\n      rootMargin: '1000px',\n      threshold: 0,\n    },\n  }"}],ref:"main",style:(_vm.myStyle)},[_c('tr',{style:(_vm.myStyle)},[_c('td',{style:(_vm.myStyle)},[_vm._t("empty",function(){return [_vm._v("...")]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }