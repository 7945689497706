<template>
  <div class="wrapper">
    <div class="label-wrapper">
      <span v-if="label && !value" class="label">{{ formattedLabel }}</span>
    </div>
    <input
      :tabindex="tabIndex"
      ref="input"
      input
      :size="size"
      :type="type"
      :disabled="disabled"
      :value="formattedValue"
      @input="input"
      @blur="blur"
      onmouseup="return false;"
      :style="`text-align: ${textAlign}`"
    />
  </div>
</template>

<script>
// Input element with custom functionality

export default {
  name: "CellInput",

  props: {
    size: {
      type: String,
      default: "3",
    },
    type: {
      type: String,
      default: "text",
    },
    variableType: {
      type: String,
      default: "number",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: null,
    },
    value: null,
    decimals: {
      default: null,
      validator: (prop) => typeof prop === "number" || prop === null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    allowTab: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: String,
      default: "right",
    },
  },

  computed: {
    tabIndex() {
      if (this.allowTab) {
        return "";
      }
      return "-1";
    },
    formattedLabel() {
      if (!this.label) {
        return "";
      }
      const floatVal = parseFloat(this.label);
      if (!floatVal) {
        return this.label;
      }
      if (this.decimals !== null) {
        return floatVal.toFixed(this.decimals);
      }
      if (floatVal % 1.0 == 0) {
        return floatVal.toFixed(0);
      }
      return floatVal.toFixed(2);
    },
    formattedValue() {
      if (this.value === "DELETE") {
        return "DEL";
      }
      if (!this.value) {
        return "";
      }
      const decimalsInProgress = (this.value.toString().split(".")[1] || "")
        .length;
      const floatVal = parseFloat(this.value);
      if (decimalsInProgress < (this.decimals || 2)) {
        // Use is in progress of tying the decimals, we don't want to autofill e.g. 12.9 to 12.90
        return this.value;
      }
      if (decimalsInProgress > (this.decimals || 2)) {
        // Prevent case where user types more decimals than visible. Bad UX since 13.126 will be displayed as 13.13
        return `${this.value.toString().split(".")[0]}.${this.value
          .toString()
          .split(".")[1]
          .slice(0, this.decimals || 2)}`;
      }
      if (this.decimals !== null) {
        return floatVal.toFixed(this.decimals);
      }
      if (floatVal % 1.0 == 0) {
        return floatVal.toFixed(0);
      }
      return floatVal.toFixed(2);
    },
  },

  methods: {
    cleanInput(event) {
      if (this.variableType == "number") {
        // Convert comma to dot on the fly
        event.target.value = event.target.value.replace(",", ".");
        if (isNaN(event.target.value.replace(".", ""))) {
          event.target.value = this.formattedValue;
          return this.formattedValue;
        }
      }
      return event.target.value;
    },
    input(event) {
      // Note that doing multiple (debounced) input emits before the previous one is actually called will
      // clear the previous one.

      // This isn't a problem normally since we always call "blur" (which does a debounce.clear())
      // before switching to an another element.
      this.$emit("input", this.cleanInput(event));
    },
    blur(event) {
      this.$emit("blur", this.cleanInput(event));
    },
    setValue(value) {
      this.$emit("input", value);
      this.$emit("blur", value);
    },
    clear() {
      this.$emit("input", "");
      this.$emit("blur", "");
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  height: 100%;
  width: 100%;
  input {
    height: 100%;
    width: 100%;
    &:focus {
      outline-width: 2px;
      outline-style: double;
      outline-color: blue;
      outline-offset: -1px;
    }
  }
  .label-wrapper {
    position: relative;
    .label {
      position: absolute;
      right: 3px;
      top: 3px;
    }
  }
}
</style>
