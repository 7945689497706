import Vue from "vue";


const changesToMake = {};
let interval = null;

function makeChanges(state) {
  const visibleComponentCount = Object.values(state.visibleComponents).filter(Boolean).length;
  let changesMade = 0;
  for (const componentId in changesToMake) {
    let newValue = changesToMake[componentId];
    if (state.visibleComponents[componentId] === newValue) {
      delete changesToMake[componentId];
      continue;
    }

    // Don't hide if visibleComponentCount is less than 100
    if (newValue === false && visibleComponentCount < state.maxVisibleComponents) {
      continue;
    }

    Vue.set(state.visibleComponents, componentId, newValue);
    delete changesToMake[componentId];
    changesMade++;

    if (changesMade > 15) {
      console.log("Breaking after 15 changes.");
      interval = setTimeout(() => makeChanges(state), 200);
      return;
    }
  }
  console.log("Made", changesMade, "changes.");
  interval = null;
}


export const disappearingHelper = {
  namespaced: true,
  state: () => ({
    visibleComponents: {},
    maxVisibleComponents: 20,
  }),
  mutations: {
    setComponentVisible(state, { componentId, visible }) {
      // Make sure the interval is running
      if (!interval) {
        interval = setTimeout(() => makeChanges(state), 200);
      }
      changesToMake[componentId] = visible;
    },
    setMaxVisibleComponents(state, maxVisibleComponents) {
      state.maxVisibleComponents = maxVisibleComponents;
    },
    clearComponentVisible(state) {
      Vue.set(state, 'visibleComponents', {});
      for (const key in changesToMake) {
        delete changesToMake[key];
      }
    }
  },
  actions: {},
  getters: {
    componentVisible: (state) => (componentId) => {
      return state.visibleComponents[componentId] || false;
    }
  },
};
