<template>
  <div class="order-list">
    <v-dialog v-model="modal" width="600">
      <v-card>
        <v-card-title> Luo uusi tilaus </v-card-title>
        <v-card-text>
          <v-text-field
            label="Tilauksen otsikko"
            v-model="modalValues.name"
          ></v-text-field>
          <v-select
            label="Valmistaja"
            v-model="modalValues.supplier"
            :items="
              (choices.suppliers || []).map((row) => ({
                value: row.id,
                text: row.name,
              }))
            "
          ></v-select>
          <v-select
            label="Sesonki"
            v-model="modalValues.season_str"
            :items="seasonChoices"
          ></v-select>
          <v-text-field
            label="Oletus toimitusvko"
            v-model="modalValues.defaultWeek"
            type="number"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="modal = false"> Peruuta </v-btn>
          <v-btn color="success" text @click="createOrder"> Luo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-btn @click="modal = true">Uusi tilaus</v-btn>
      </v-col>
      <v-col>
        <v-text-field
          style="width: 15rem"
          v-model="searchString"
          label="Tekstihaku"
        >
        </v-text-field>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-data-table
      show-select
      single-select
      multi-sort
      v-model="selectedOrder"
      :loading="loading"
      :sort-by="['modified']"
      :sort-desc="true"
      :items="orders"
      :headers="columns"
      :items-per-page="1000"
      :search="searchString"
      show-group-by
    >
      <template v-slot:[`item.owner`]="{ item }">
        {{ (item.owner || {}).username }}
      </template>
      <template v-slot:[`item.modified`]="{ item }">
        {{ new Date(item.modified).toLocaleString("fi-fi") }}
      </template>
      <template v-slot:[`item.created`]="{ item }">
        {{ new Date(item.created).toLocaleString("fi-fi") }}
      </template>
      <template v-slot:[`item.supplier`]="{ item }">
        {{
          (
            ((choices.suppliers || []).filter(
              (row) => row.id == item.supplier
            ) || [null])[0] || {}
          ).name
        }}
      </template>
      <template v-slot:[`item.action_delete`]="{ item }">
        <v-btn
          v-if="item.total_color_groups == 0"
          icon
          @click="deleteOrder(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ChoicesOrderVue from "../mixins/ChoicesOrder.vue";
export default {
  name: "OrderList",
  components: {},
  mixins: [ChoicesOrderVue],
  data: function () {
    return {
      loading: true,
      orders: [],
      modal: false,
      modalValues: {
        name: null,
        supplier: null,
        defaultWeek: null,
        season_str: null,
      },
      searchString: "",
      columns: [
        {
          text: "ID",
          value: "id",
          groupable: false,
        },
        {
          text: "Luotu",
          value: "created",
          groupable: false,
        },
        {
          text: "Muokattu",
          value: "modified",
          groupable: false,
        },
        {
          text: "Nimi",
          value: "name",
          groupable: false,
        },
        {
          text: "Toimittaja",
          value: "supplier",
        },
        {
          text: "Sesonki",
          value: "season_str",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Luonut",
          value: "owner",
        },
        {
          text: "Tuotemalleja",
          value: "total_color_groups",
          groupable: false,
        },
        {
          text: "Tuotteita kpl",
          value: "total_quantity",
          groupable: false,
        },
        {
          text: "Yhteensä (nto)",
          value: "total_price",
          groupable: false,
        },
        {
          text: "Poista",
          value: "action_delete",
          groupable: false,
        },
      ],
    };
  },

  mounted() {
    this.fetchOrders();
  },

  computed: {
    selectedOrder: {
      get() {
        if (this.$route.params.orderId && this.orders) {
          const orders = this.orders.filter(
            (o) => o.id == this.$route.params.orderId
          );
          if (orders.length > 0) {
            return [orders[0]];
          }
        }
        return [];
      },
      set(newValue) {
        let ret = "";
        if (newValue[0]) {
          ret = newValue[0].id;
        }
        if (this.$route.params.orderId == ret) {
          return;
        }
        if (!this.$route.params.orderId && !ret) {
          return;
        }

        this.$router.push("/orders/" + ret);
      },
    },
    seasonChoices() {
      const startingYear = (new Date().getFullYear() - 1) % 1000;
      const ret = [];
      for (let i = 0; i < 10; i++) {
        ret.push({
          value: `SS${startingYear + i}`,
          text: `SS${startingYear + i}`,
        });
        ret.push({
          value: `FW${startingYear + i}`,
          text: `FW${startingYear + i}`,
        });
      }
      return ret;
    },
  },

  methods: {
    fetchOrders() {
      this.axios.get("/api/orders/orders/").then((resp) => {
        this.orders = resp.data.map((order) => {
          return order;
        });
        this.axios.get("/api/orders/orders_extra/").then((resp) => {
          this.loading = false;
          const ordersById = {};
          for (const order of this.orders) {
            ordersById[order.id] = order;
          }
          for (const newOrder of resp.data) {
            if (ordersById[newOrder.id]) {
              this.$set(ordersById[newOrder.id], 'total_color_groups',
                newOrder.total_color_groups);
              this.$set(ordersById[newOrder.id], 'total_quantity',
                newOrder.total_quantity);
              this.$set(ordersById[newOrder.id], 'total_price', newOrder.total_price);
            }
          }
        })
      });
    },
    createOrder() {
      let data = {};
      data.name = this.modalValues.name;
      data.supplier = this.modalValues.supplier;
      data.default_date = this.modalValues.defaultWeek;
      data.season_str = this.modalValues.season_str;
      this.axios.post("/api/orders/orders/", data).then((resp) => {
        console.log(resp);
        this.$router.push(`/order/${resp.data.id}/edit`);
        this.modal = false;
      });
    },
    deleteOrder(order) {
      this.axios.delete(`/api/orders/order/${order.id}/edit/`).then((resp) => {
        this.orders = this.orders.filter((o) => o.id != order.id);
      });
    },
  },
};
</script>
